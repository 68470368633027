<template>
  <div :style="f_calculateStyleWindow()" class="animated fadeIn">
    <template v-if="d_startDecisionSupport && d_chatBotDiagram.show" v-model="d_chatBotDiagram.show" size="xl" title="ChatBot Diagram Ekranı" centered hide-footer body-bg-variant="white" header-bg-variant="secondary" no-close-on-esc no-close-on-backdrop>
      <b-row :style="f_calculateMenuStyle()">
        <!--         <b-col cols="2" style="padding: 3px;">
          <b-button title="Chatbot hakkında bilgi" variant="white" @click="f_infoDmp()">
            <img src="@/icon/223370.png" style="width: 2em;">
          </b-button>
        </b-col>
 -->
        <b-col cols="4" style="padding: 3px;">
          <!-- 
          <b-button title="Görünüm değiştir" variant="white" @click="f_changeStyle()">
            <img src="@/icon/403386.png" style="width: 2em;">
          </b-button>
          <b-button title="Menü yerini değiştir." variant="white" @click="f_changeMenuStyle()">
            <img src="@/icon/2751062.png" style="width: 2em;">
          </b-button>
           -->
          <b-button title="En başa dön" variant="white" @click="f_startDecisionTree()">
            <img src="@/icon/354225.png" style="width: 2em;">
          </b-button>
          <b-button v-if="d_selectedLanguageIndex !== '' && d_languageIconInWchatbotScreen" title="Dil seçimi" variant="white" @click="f_changeLanguage()">
            <strong style="color: #20a2ce;">
              {{ d_languageList[d_selectedLanguageIndex].value }}
            </strong>
          </b-button>
          <!--           <b-button v-if="d_selectedWdm308ApiDocument && d_selectedWdm308ApiDocument.id" title="api" variant="white">
            <img src="@/icon/1716471.png" style="width: 2em;">
          </b-button>
 -->
        </b-col>
        <b-col cols="4" style="padding: 3px; text-align: center;">
          <b-button v-if="d_sandWatch.loading" title="Geri" variant="white">
            <img src="@/icon/sandwatchblue.gif" style="width: 2em;">
          </b-button>
        </b-col>
        <b-col cols="4" style="padding: 3px; text-align: right;">
          <b-button v-if="d_chatBotDiagram.last_diagram_point && !d_chatBotDiagram.loading" title="Geri" variant="white" @click="f_chatBotDiagramBack()">
            <img src="@/icon/2457527.png" style="width: 2em;">
          </b-button>
          <!-- <b-button variant="white" @click="f_chatBotDiagramAnalyze()" title="Diyagram Analizi">
                <img src="@/icon/2024270.png" style="width: 5em;">
              </b-button> -->
        </b-col>
      </b-row>
      <div v-if="d_chatBotType === 'decision_tree'">
        <b-card no-body class="animated fadeIn" style="margin-bottom: 3px; margin-left: 10px; margin-right: 10px">
          <b-row>
            <b-col cols="12">
              <template v-for="(algorithm, algorithm_ind) in d_selectedAlgorithmList">
                <div style="padding: 3px; float: left;"> <i class="fa fa-check-circle" style="color: #00c3f;"></i> {{ algorithm.message ? algorithm.message : algorithm.name }} </div>
                <div v-if="algorithm_ind !== d_selectedAlgorithmList.length - 1" style="color: blue; padding: 3px; float: left;"> + </div>
              </template>
            </b-col>
          </b-row>
        </b-card>
        <template v-if="d_chatBotDiagram.algorithm_list.length > 0">
          <b-row style="padding: 10px;">
            <b-col cols="12">
              <b-input-group>
                <b-form-input id="search_text_algorithm" type="search" v-on:search="f_searchInDiagram" v-model="d_algorithmSearch.text" :placeholder="f_languageText('filtreleme alanı.. kelime aralarına virgül ekleyebilirsiniz.')" @keydown.enter.native="ClickEnter"></b-form-input>
                <b-input-group-append>
                  <b-button size="lg" style="margin-right: 5px; padding: 0px;" variant="white" @click="f_deleteSearchText()" title="temizle">
                    <img src="@/icon/3934901.png" style="width: 30px;" />
                  </b-button>
                  <b-button size="lg" class="pull-right" style="padding: 0px; width: 100px; border: solid 1px #20a2ce;" variant="white" @click="f_searchInDiagram()" title="ara">
                    <img src="@/icon/9873.png" style="width: 30px;" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <template v-if="d_algorithmSearch.loading">
            <b-card no-body style="cursor: pointer; margin-bottom: 5px; margin-left: 10px; margin-right: 10px;min-height: 50px; vertical-align: middle; padding-top: 10px;" class="animated fadeIn">
              <b-row style="margin: 0px; vertical-align: middle;">
                <b-col cols="3" style="padding: 3px;vertical-align: middle;">
                  <img src="@/icon/sandwatchblue.gif" style="width: 2em;">
                </b-col>
                <b-col cols="9" style="padding: 3px; vertical-align: middle;">
                  <span style="color: #1899d5;">
                    {{ f_languageText('işlem devam ediyor') }}
                  </span>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-else>
            <template v-if="d_algorithmSearch.text">
              <template v-if="d_algorithmSearch.search_list.length > 0">
                <template v-for="(search_algorithm, search_algorithm_ind) in d_algorithmSearch.search_list">
                  <b-card no-body style="cursor: pointer; margin-bottom: 5px; margin-left: 10px; margin-right: 10px;min-height: 50px; vertical-align: middle;" class="animated fadeIn">
                    <b-row style="margin: 0px; vertical-align: middle;">
                      <b-col cols="3" style="padding: 3px;vertical-align: middle;">
                        <img src="@/icon/223370.png" style="width: 2em;">
                      </b-col>
                      <b-col cols="9" style="padding: 3px; vertical-align: middle;" @click="f_selectSearchedDiagramPointAndGo(search_algorithm)">
                        <span style="font-size: 16px;"> {{ search_algorithm.label }} </span>
                        <!-- <span style="font-size: 16px;"> {{ search_algorithm.diagram_point }} </span> -->
                      </b-col>
                    </b-row>
                  </b-card>
                </template>
              </template>
              <template v-else>
                <b-card v-if="d_showNoSearchResult" no-body style="cursor: pointer; margin-bottom: 5px; margin-left: 10px; margin-right: 10px;min-height: 50px; vertical-align: middle; padding-top: 10px;" class="animated fadeIn">
                  <b-row style="margin: 0px; vertical-align: middle;">
                    <b-col cols="3" style="padding: 3px;vertical-align: middle;">
                      <img src="@/icon/3773931.png" style="width: 2em;">
                    </b-col>
                    <b-col cols="9" style="padding: 3px; vertical-align: middle;">
                      <span style="color: #1899d5;">Sonuç bulunamadı</span>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </template>
            <template v-else>
              <template v-for="(algorithm, algorithm_ind) in d_chatBotDiagram.algorithm_list">
                <!-- <b-card v-if="f_filterAlgorithm(algorithm)" no-body :style="f_calculateAlgorithmStyle(algorithm)" class="animated fadeIn"> -->
                <b-card no-body :style="f_calculateAlgorithmStyle(algorithm)" class="animated fadeIn">
                  <b-row style="margin: 0px; vertical-align: middle;">
                    <b-col cols="3" style="padding: 3px;vertical-align: middle;">
                      <template v-if="algorithm.note">
                        <img src="@/icon/223370.png" style="width: 2em;" @click="f_showAlgorithmInfo(algorithm)">
                      </template>
                      <template v-if="algorithm.message_image">
                        <img :src="algorithm.message_image" style="height: 80%; margin: 3px;">
                      </template>
                      <template v-else>
                        <img src="@/icon/1430869.png" style="width: 5em; margin: 3px;">
                      </template>
                    </b-col>
                    <b-col cols="9" style="padding: 3px;vertical-align: middle;" @click="f_selectDiagramPointAndGo(algorithm)">
                      <span style="font-size: 16px;"> {{ algorithm.message }} </span>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </template>
          </template>
        </template>
        <template v-else>
          <b-card no-body @click="f_chatBotDiagramBack()" :style="f_calculateAlgorithmStyle()">
            <b-row style="margin: 0px;">
              <b-col sm="12" lg="12" style="text-align: center;">
                <img src="@/icon/2457527.png" style="width: 5em;">
              </b-col>
            </b-row>
          </b-card>
        </template>
      </div>
      <div v-else-if="d_chatBotType === 'search_and_add'">
        <!-- 
        <b-row style="padding: 10px; 200px; background: #a7d4cc; margin: 10px; border-radius: 10px 10px; border: solid 1px lightblue; border: 2px solid lightblue; box-shadow: -1px -1px 0px 0px lightblue;">
          <b-col cols="12" style="padding: 1px;">
            <template v-if="d_talkList.length > 0">
              <template v-for="(talk, talk_ind) in d_talkList">
                <div v-if="talk.talk_type === 'customer'" style="padding: 3px; float: left;"> <i class="fa fa-check-circle" style="color: #00c3f;"></i> {{ talk.message }} </div>
              </template>
            </template>
          </b-col>
        </b-row>
         -->
        <b-row style="padding: 10px; overflow-x: hidden; overflow-y: auto; height: 400px; background: #a7c8d4; margin: 10px; border-radius: 10px 10px; border: solid 1px lightblue; border: 2px solid lightblue; box-shadow: -1px -1px 0px 0px lightblue;">
          <b-col cols="12" style="padding: 1px;">
            <template v-if="d_talkList.length > 0">
              <template v-for="(talk, talk_ind) in d_talkList">
                <b-row v-if="talk.talk_type === 'chatbot'" style="margin: 5px;">
                  <b-col cols="9" style="padding-left: 1px;">
                    <div :style="f_calculateTalkStyle(talk)">
                      {{ talk.message }}
                    </div>
                  </b-col>
                  <b-col cols="3">
                  </b-col>
                </b-row>
                <b-row v-if="talk.talk_type === 'customer'" style="margin: 5px;">
                  <b-col cols="3">
                  </b-col>
                  <b-col cols="9" style="padding-right: 2px;">
                    <div :style="f_calculateTalkStyle(talk)">
                      {{ talk.message }}
                    </div>
                  </b-col>
                </b-row>
              </template>
            </template>
          </b-col>
        </b-row>
        <b-row style="overflow-x: hidden; overflow-y: auto; height: 150px; background: #a7c8d4; margin: 10px; border-radius: 10px 10px; border: solid 1px lightblue; border: 2px solid lightblue; box-shadow: -1px -1px 0px 0px lightblue;">
          <b-col cols="12">
            <b-row v-for="(search_algorithm, search_algorithm_ind) in d_algorithmSearch.search_list">
              <b-col cols="11">
                <div style="box-shadow: -1px -1px 1px; border-radius: 2px 2px; margin-top: 3px; margin-bottom: 3px; vertical-align: middle; cursor: pointer; background: #ebfffb; padding: 5px;" @click="f_addAlgorithmSearchList(search_algorithm)">
                  <span style="font-size: 12px;"> {{ search_algorithm.message }} </span>
                </div>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="padding-right: 10px; padding-left: 10px; bottom: 0px;">
          <b-col cols="12">
            <b-form-input id="search_text_algorithm" :disabled="d_algorithmSearch.loading" type="search" v-on:search="f_searchInDiagram" v-model="d_algorithmSearch.text" :placeholder="f_languageText('filtreleme alanı.. kelime aralarına virgül ekleyebilirsiniz.')" @keydown.enter.native="ClickEnter" style="min-height: 50px; border-radius: 2px 2px; background-color: rgb(167, 200, 212);" autocomplete="off"></b-form-input>
          </b-col>
        </b-row>
        <!-- 
          <b-row id="context-menu-popup" class="class-context-menu-popup" style="position: fixed; z-index: 1000; display: none; background-color: #d8d8d8; box-shadow: 1px -1px 3px 0px black; overflow-y: auto; overflow-x: hidden; height: 200px; margin-left: 10px; padding-left: 10px; margin-right: 10px; padding-right: 10px; width: 100%;">
            <b-col cols="12">
              <template v-for="(search_algorithm, search_algorithm_ind) in d_algorithmSearch.search_list">
                <b-row style="margin: 0px; vertical-align: middle;">
                  <b-col cols="12" style="padding: 3px; vertical-align: middle;" @click="f_addAlgorithmSearchList(search_algorithm)">
                    <span style="font-size: 16px;"> {{ search_algorithm.message }} </span>
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
         -->
        <b-row v-if="false" style="padding: 10px;">
          <b-col cols="12">
            <speech-to-text :p_speechResults="d_speechResults"></speech-to-text>
          </b-col>
        </b-row>
        <b-row v-if="false" style="padding: 10px;">
          <b-col cols="12">
            <template v-if="d_selectedSearchAlgorithmList.length > 0">
              <template v-for="(search_algorithm, search_algorithm_ind) in d_selectedSearchAlgorithmList">
                <b-card no-body style="cursor: pointer; margin-bottom: 5px; margin-left: 10px; margin-right: 10px; vertical-align: middle;" class="animated fadeIn">
                  <b-row style="margin: 0px; vertical-align: middle;">
                    <b-col cols="3" style="padding: 3px;vertical-align: middle;">
                      <img src="@/icon/223370.png" style="width: 2em;">
                    </b-col>
                    <b-col cols="9" style="padding: 3px; vertical-align: middle;" @click="f_deleteAlgorithmFromSearchList(search_algorithm_ind)">
                      <span style="font-size: 16px;"> {{ search_algorithm.message }} </span>
                      <!-- <span style="font-size: 16px;"> {{ search_algorithm.diagram_point }} </span> -->
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </template>
          </b-col>
        </b-row>
      </div>
    </template>
    <template v-if="d_selectedDmpWdmrData.id && !d_startDecisionSupport">
      <b-card class="animated fadeIn">
        <b-card-header header-bg-variant="white" class="p-1">
          <b-row style="margin: 0px;">
            <b-col sm="12" md="12">
              {{ $t('wdm16.11220') }} {{ d_selectedDmpWdmrData.label }}
            </b-col>
          </b-row>
        </b-card-header>
        <template v-if="d_selectedDmpWdmrData.id">
          <b-row v-if="d_selectedDmpWdmrData.data.general.information" style="margin: 0px; border: solid 1px #d8f6d8; padding: 3px; border-radius: 5px; min-height: 50px;">
            <b-col sm="12" md="12">
              {{ d_selectedDmpWdmrData.data.general.information.val }}
            </b-col>
          </b-row>
          <b-row v-if="d_selectedDmpWdmrData.data.general.about_algotihms && d_selectedDmpWdmrData.data.general.about_algotihms.list && d_selectedDmpWdmrData.data.general.about_algotihms.list.length > 0" style="margin: 0px; border: solid 1px #d8f6d8; padding: 3px; border-radius: 5px;">
            <template v-for="(about, about_ind) in d_selectedDmpWdmrData.data.general.about_algotihms.list">
              <b-col sm="12" md="12">
                <template v-if="about.image && about.image.val">
                  <img :src="about.image.val" style="width: 100%;">
                  <br>
                </template>
                <template v-if="about.about_information && about.about_information.val">
                  <span style="margin-bottom: 0px;">{{ about.about_information.val }}</span>
                </template>
              </b-col>
            </template>
          </b-row>
        </template>
      </b-card>
    </template>
    <template v-if="d_selectedDmpWdmrData.id && !d_startDecisionSupport">
      <b-card no-body @click="f_startDecisionTree()" :style="f_calculateAlgorithmStyle()">
        <b-row style="margin: 0px; vertical-align: middle;">
          <b-col sm="12" lg="12">
            <img src="@/icon/1430869.png" style="width: 5em;"> BAŞLA
          </b-col>
        </b-row>
      </b-card>
    </template>
    <template v-if="d_apiOperationIsContinuing">
      <b-card no-body :style="f_calculateAlgorithmStyle()">
        <b-row style="margin: 0px; vertical-align: middle;">
          <b-col sm="12" lg="12">
            <img src="@/icon/1430869.png" style="width: 5em;"> {{ f_languageText('işlem devam ediyor') }}
          </b-col>
        </b-row>
      </b-card>
    </template>
    <template v-if="!d_selectedDmpWdmrData.id">
      <b-card no-body :style="f_calculateAlgorithmStyle()">
        <b-row style="margin: 0px; vertical-align: middle;">
          <b-col sm="12" lg="12">
            <img src="@/icon/1430869.png" style="width: 5em;"> {{ f_languageText('Yükleniyor lütfen bekleyiniz...') }}
          </b-col>
        </b-row>
      </b-card>
    </template>
    <b-modal v-if="d_algorithmInfo.show" v-model="d_algorithmInfo.show" scrollable title="Bilgi" centered header-bg-variant="info" header-text-variant="dark" hide-footer>
      <b-row>
        <b-col sm="12" md="6" lg="6" style="text-align: center;">
          <template v-if="d_algorithmInfo.algorithm.message_image">
            <img :src="d_algorithmInfo.algorithm.message_image" style="height: 100%;">
          </template>
          <template v-else>
            <img src="@/icon/1430869.png" style="width: 33%;">
          </template>
        </b-col>
        <b-col sm="12" md="6" lg="6">
          {{ d_algorithmInfo.algorithm.note }}
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  default as SpeechToText
} from '@/components/widgets/SpeechToText';
import ChatBotService from '@/services/chatbot';
import WdmService from '@/services/wdm';
import GlobalService from '@/services/global';
import { ClsWdmrManage } from "@/services/public/wdmr_manage";
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  default as router
} from '@/router';

export default {
  name: 'ChatBotIframe',
  computed: {
    ...mapGetters({
      device: 'device'
    })
  },
  props: {},
  components: {
    SpeechToText
  },
  data () {
    return {
      d_screenMode: 'middle',
      d_speechResults: { 'text': '' },
      d_talkList: [],
      d_selectedSearchAlgorithmList: [],
      d_chatBotType: 'decision_tree',
      d_routeLang: '',
      d_languageIconInWchatbotScreen: false,
      d_langMatch: {
        'ar': ['ar', 'ar-sa'],
        'en': ['en', 'en-us'],
        'ru': ['ru', 'ru-ru'],
        'tr': ['tr', 'tr-tr'],
      },
      d_selectedLanguageIndex: '',
      d_languageList: [],
      d_showNoSearchResult: false,
      d_sandWatch: { 'loading': false },
      d_apiOperationIsContinuing: false,
      d_algorithmSearch: { 'text': '', 'search_list': [], 'loading': false, 'timeout': 0 },
      d_algorithmInfo: { 'show': false, 'algorithm': '' },
      d_selectedAlgorithmList: [],
      d_startDecisionSupport: true,
      d_selectedWdm308ApiDocument: '',
      d_standartVariablesOfQuery: ['dmp_id', 'menustyle', 'style', 'diagram_point', 'chatbot_token', 'lang'],
      d_clientVariables: {},
      d_selectedMenuStyle: 0,
      d_menuStyleList: [{
        "background": "",
        "position": "",
        "bottom": "0",
        "margin": "0",
        "top": "0",
        "width": "100%"
      }, {
        "background": "",
        "position": "fixed",
        "bottom": "0",
        "margin": "0",
        "top": "",
        "width": "100%"
      }, {
        "background": "white",
        "position": "",
        "bottom": "",
        "margin": "0",
        "top": "",
        "width": "100%"
      }, {
        "background": "white",
        "position": "fixed",
        "bottom": "0",
        "margin": "0",
        "top": "",
        "width": "100%"
      }],
      d_selectedStyleIndex: 0,
      d_styleList: [{
        "screen": {
          "background": ""
        },
        "algorithm": {
          "background": "white",
          "background_selected": "#d1f5f9"
        }
      }, {
        "screen": {
          "background": "#f2a3ff"
        },
        "algorithm": {
          "background": "white",
          "background_selected": "#d1f5f9"
        }
      }, {
        "screen": {
          "background": "#83d6fd"
        },
        "algorithm": {
          "background": "white",
          "background_selected": "#d1f5f9"
        }
      }, {
        "screen": {
          "background": "white"
        },
        "algorithm": {
          "background": "#f2a3ff",
          "background_selected": "#d1f5f9"
        }
      }, {
        "screen": {
          "background": "white"
        },
        "algorithm": {
          "background": "#83d6fd",
          "background_selected": "#d1f5f9"
        }
      }, {
        "screen": {
          "background": "#86bdab"
        },
        "algorithm": {
          "background": "white",
          "background_selected": "#d1f5f9"
        }
      }, {
        "screen": {
          "background": "#005780"
        },
        "algorithm": {
          "background": "white",
          "background_selected": "#d1f5f9"
        }
      }, {
        "screen": {
          "background": ""
        },
        "algorithm": {
          "background": "#86bdab",
          "background_selected": "#d1f5f9"
        }
      }, {
        "screen": {
          "background": ""
        },
        "algorithm": {
          "background": "#005780",
          "background_selected": "#d1f5f9"
        }
      }],
      d_chatBotDiagram: {
        'loading': '',
        'chatbot_token': '',
        'show': false,
        'diagram_point': '',
        'last_diagram_point': '',
        'algorithm_list': [],
        'selected_algorithm': ''
      },
      d_selectedWdmrIndex_dmp: '',
      d_dmpList: {},
      d_selectedDmpWdmrData: '',
      user: '',
      wdm22: '',
      d_wdm24List: [],
      d_wisdomData: {
        'view_mode': 'table',
        'change': '0',
        'show': false,
        'wisdom_data': {},
        'data_type': '',
        'wisdom_op': '',
        'option_data': {}
      },
      d_wdm22Data: { 'id': '' }
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.wdm22 = JSON.parse(localStorage.getItem('wdm22'));
  },
  mounted: function () {
    // console.log(this.$route.query);
    // console.log("this.$route.query.dmp_id", this.$route.query.dmp_id);
    if (this.$route.query) {
      if (this.$route.query.lang) {
        this.d_routeLang = this.$route.query.lang.toLowerCase();
      }
      if (this.$route.query.diagram_point !== undefined) {
        let target_diagram_point = this.$route.query.diagram_point;
        this.d_chatBotDiagram.diagram_point = target_diagram_point;
        this.d_startDecisionSupport = true;
        let route_data = { 'query': {} };
        for (let k in this.$route.query) {
          if (k !== 'diagram_point') {
            route_data['query'][k] = this.$route.query[k];
          }
        }
        this.$router.push(route_data);
      }
      if (this.$route.query.chatbot_token !== undefined) {
        this.d_chatBotDiagram.chatbot_token = this.$route.query.chatbot_token;
      }
      // console.log("this.d_chatBotDiagram.chatbot_token ", this.d_chatBotDiagram.chatbot_token);
      if (this.$route.query.dmp_id !== undefined) {
        if (this.d_chatBotDiagram.chatbot_token) {
          this.f_controlAndGetDmpByThisToken();
        } else {
          this.f_getUserDmpList();
        }
      } else {
        let msg = 'Karar destek paketi id tanımlı değil.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': '' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
      if (this.$route.query.style !== undefined) {
        try {
          let k = parseInt(this.$route.query.style);
          this.d_selectedStyleIndex = k;
        } catch (err) {
          this.d_selectedStyleIndex = 0;
          console.log(err);
        }
      }
      if (this.$route.query.screen_mode !== undefined) {
        if (['fullscreen', 'middle'].indexOf(this.$route.query.screen_mode) !== -1) {
          this.d_screenMode = this.$route.query.screen_mode;
        }
      }
      if (this.$route.query.menustyle !== undefined) {
        try {
          let k = parseInt(this.$route.query.menustyle);
          this.d_selectedMenuStyle = k;
        } catch (err) {
          this.d_selectedMenuStyle = 0;
          console.log(err);
        }
      }
      for (let k in this.$route.query) {
        if (this.d_standartVariablesOfQuery.indexOf(k) === -1) {
          this.d_clientVariables[k] = this.$route.query[k];
        }
      }
    }
    this.$forceUpdate();
    // console.log('this.d_clientVariables ', this.d_clientVariables);
  },
  methods: {
    f_addAlgorithmSearchList: function (search_algorithm) {
      this.d_algorithmSearch.text = '';
      this.d_algorithmSearch.search_list = [];
      let context_menu_popup = document.getElementById('context-menu-popup');
      if (context_menu_popup) {
        context_menu_popup.style.display = 'none';
      }
      let det = false;
      for (let i in this.d_selectedSearchAlgorithmList) {
        if (search_algorithm.id === this.d_selectedSearchAlgorithmList[i].id) {
          det = true;
          break;
        }
      }
      if (!det) {
        this.d_selectedSearchAlgorithmList.push(search_algorithm);
        search_algorithm.talk_type = 'customer';
        this.d_talkList.push(search_algorithm);
        let will_continue_search = true;
        this.d_chatBotDiagram.selected_algorithm = search_algorithm;
        if (this.d_selectedWdm308ApiDocument && this.d_selectedWdm308ApiDocument.id) {
          if (search_algorithm.operation_id) {
            will_continue_search = false;
            this.f_apiRuleOperation();
          }
        }
        if (will_continue_search) {
          this.f_analyzeWithTalkList();
        }
      }
    },
    f_analyzeWithTalkList: function () {
      let caret_coordinates = '';
      if (!this.d_algorithmSearch.loading) {
        let eligible_for_continue = false;
        let query = "dmp_id=" + this.$route.query.dmp_id;
        query += '&chatbot_token=' + this.d_chatBotDiagram.chatbot_token;
        if (this.d_selectedLanguageIndex !== '') {
          query += '&lang=' + this.d_languageList[this.d_selectedLanguageIndex].value;
        }
        if (this.d_chatBotDiagram.diagram_point) {
          query += '&diagram_point=' + this.d_chatBotDiagram.diagram_point;
        }
        let data = {
          'talk_list': this.d_talkList
        };
        this.d_algorithmSearch.loading = true;
        ChatBotService.chatbot_analyze_with_talk_list(query, data)
          .then(resp => {
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            this.d_algorithmSearch.loading = false;
            if (resp.data.status_code === '1000') {
              this.d_algorithmSearch.search_list = resp.data.search_list;
              if (this.d_algorithmSearch.search_list.length === 0) {
                this.d_showNoSearchResult = true;
              } else {
                /*
                  let chatbot_talk = {
                    'message': 'LÜtfen seçiminizi yapınız',
                    'talk_type': 'chatbot'
                  };
                  this.d_talkList.push(chatbot_talk);
                */
              }
              if (this.d_chatBotType === 'search_and_add') {
                let after_finding = this.d_algorithmSearch.search_list.length;
                // this.f_getCursorPosition();
                // console.log('caret_coordinates ', caret_coordinates);
                let context_menu_popup = document.getElementById('context-menu-popup');
                // let position = this.F_getCaretIndex(div_el_wdmr_sentence_template);
                // console.log('position ', position);
                // let charCount = this.f_getCurrentCursorPosition('wdmr_sentence_template');
                // console.log('charCount ::::: ', charCount);
                console.log("context_menu_popup : ", context_menu_popup);
                console.log("caret_coordinates  : ", caret_coordinates);
                console.log("after_finding      : ", after_finding);
                /*
                 */
                if (after_finding === 0) {
                  this.d_activeKeyDownText = '';
                  // context_menu_popup.style.left = '0px';
                  // context_menu_popup.style.top = '0px';
                  if (context_menu_popup) {
                    context_menu_popup.style.display = 'none';
                  }
                } else {
                  if (context_menu_popup) {
                    context_menu_popup.style.display = '';
                  }
                  /*
                    if (caret_coordinates.x !== 0 && caret_coordinates.y !== 0) {
                      context_menu_popup.style.left = (caret_coordinates.x).toString() + 'px';
                      context_menu_popup.style.top = (caret_coordinates.y + 10).toString() + 'px';
                      context_menu_popup.style.display = '';
                    }
                  */
                }
              }
            } else {
              this.d_showNoSearchResult = true;
              let msg = '';
              msg = resp.data.status_message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': '' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
              this.$forceUpdate();
            }
          });


      }
    },
    f_calculateTalkStyle: function (talk) {
      let style = "background: white; border-radius: 5px 5px; padding: 3px; box-shadow: -1px -1px #565656; font-size: 12px;";
      if (talk.talk_type === 'customer') {
        style += 'color: black; font-size: 12px;';
      } else {
        style += 'color: black; font-size: 12px;';
      }
      return style;
    },
    f_deleteAlgorithmFromSearchList: function (search_algorithm_ind) {
      this.d_selectedSearchAlgorithmList.splice(search_algorithm_ind, 1);
      this.$forceUpdate();
    },
    f_languageText: function (txt) {
      let translation_text = txt;
      let target_lang = '';
      if (this.d_selectedLanguageIndex === '') {
        if (this.d_routeLang) {
          if (['tr', 'tr-tr'].indexOf(this.d_routeLang) !== -1) {
            target_lang = 'tr';
          } else if (['en', 'en-us'].indexOf(this.d_routeLang) !== -1) {
            target_lang = 'en';
          } else if (['ru', 'ru-ru'].indexOf(this.d_routeLang) !== -1) {
            target_lang = 'ru';
          } else if (['ar', 'ar-sa'].indexOf(this.d_routeLang) !== -1) {
            target_lang = 'ar';
          }
        }
      } else {
        if (this.d_languageList[this.d_selectedLanguageIndex].value) {
          if (['tr', 'tr-tr'].indexOf(this.d_languageList[this.d_selectedLanguageIndex].value) !== -1) {
            target_lang = 'tr';
          } else if (['en', 'en-us'].indexOf(this.d_languageList[this.d_selectedLanguageIndex].value) !== -1) {
            target_lang = 'en';
          } else if (['ru', 'ru-ru'].indexOf(this.d_languageList[this.d_selectedLanguageIndex].value) !== -1) {
            target_lang = 'ru';
          } else if (['ar', 'ar-sa'].indexOf(this.d_languageList[this.d_selectedLanguageIndex].value) !== -1) {
            target_lang = 'ar';
          }
        }
      }
      if (target_lang) {
        if (target_lang === 'tr') {
          if (txt === 'filtreleme alanı.. kelime aralarına virgül ekleyebilirsiniz.') {
            translation_text = 'filtreleme alanı.. kelime aralarına virgül ekleyebilirsiniz.';
          } else if (txt === 'Yükleniyor lütfen bekleyiniz...') {
            translation_text = 'Yükleniyor lütfen bekleyiniz...';
          } else if (txt === 'işlem devam ediyor') {
            translation_text = 'işlem devam ediyor';
          }
        } else if (target_lang === 'en') {
          if (txt === 'filtreleme alanı.. kelime aralarına virgül ekleyebilirsiniz.') {
            translation_text = 'search (commas between words)';
          } else if (txt === 'Yükleniyor lütfen bekleyiniz...') {
            translation_text = 'Loading please wait...';
          } else if (txt === 'işlem devam ediyor') {
            translation_text = 'in progress';
          }
        } else if (target_lang === 'ru') {
          if (txt === 'filtreleme alanı.. kelime aralarına virgül ekleyebilirsiniz.') {
            translation_text = 'поиск (запятые между словами)';
          } else if (txt === 'Yükleniyor lütfen bekleyiniz...') {
            translation_text = 'Загрузка, пожалуйста подождите...';
          } else if (txt === 'işlem devam ediyor') {
            translation_text = 'транзакция в процессе';
          }
        } else if (target_lang === 'ar') {
          if (txt === 'filtreleme alanı.. kelime aralarına virgül ekleyebilirsiniz.') {
            translation_text = 'بحث (فواصل بين الكلمات)';
          } else if (txt === 'Yükleniyor lütfen bekleyiniz...') {
            translation_text = 'جاري التحميل انتظر من فضلك';
          } else if (txt === 'işlem devam ediyor') {
            translation_text = 'المعاملة قيد التقدم';
          }
        }
      }
      return translation_text;
    },
    f_changeLanguage: function () {
      if (this.d_selectedLanguageIndex === this.d_languageList.length - 1) {
        this.d_selectedLanguageIndex = 0;
      } else {
        this.d_selectedLanguageIndex += 1;
      }
      let route_data = {
        'query': {
          'dmp_id': this.$route.query.dmp_id,
          'lang': this.d_languageList[this.d_selectedLanguageIndex].value,
        }
      };
      if (Object.keys(this.d_clientVariables).length > 0) {
        for (let i in this.d_clientVariables) {
          route_data.query[i] = this.d_clientVariables[i];
        }
      }
      if (this.$route.query.chatbot_token !== undefined) {
        route_data.query.chatbot_token = this.$route.query.chatbot_token;
      }
      if (this.$route.query.style !== undefined) {
        route_data.query.style = this.$route.query.style;
      }
      this.$router.push(route_data);
      this.f_chatBotDiagramAnalyze();
    },
    ClickEnter: function () {
      // console.log('event.which ', event.which);
      if (event.which === 13) {
        this.f_searchInDiagram();
      }
    },
    f_searchInDiagram: function () {
      let caret_coordinates = '';
      if (!this.d_algorithmSearch.loading) {
        this.d_algorithmSearch.search_list = [];
        let eligible_for_continue = false;
        if (this.d_chatBotType === 'search_and_add') {
          // caret_coordinates = this.f_getCaretCoordinates();
          eligible_for_continue = true;
        } else if (this.d_chatBotType === 'decision_tree') {
          if (this.d_algorithmSearch.text.length >= 3) {
            eligible_for_continue = true;
          } else {
            alert('Lütfen 3 veya daha fazla karakter giriniz.');
          }
        }
        if (eligible_for_continue) {
          let query = "dmp_id=" + this.$route.query.dmp_id;
          query += '&chatbot_token=' + this.d_chatBotDiagram.chatbot_token;
          if (this.d_selectedLanguageIndex !== '') {
            query += '&lang=' + this.d_languageList[this.d_selectedLanguageIndex].value;
          }
          if (this.d_chatBotDiagram.diagram_point) {
            query += '&diagram_point=' + this.d_chatBotDiagram.diagram_point;
          }
          let data = {
            'search_text': this.d_algorithmSearch.text
          };
          // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Arama yapılıyor. Lütfen bekleyiniz.' } });
          this.d_algorithmSearch.loading = true;
          ChatBotService.search_in_diagram(query, data)
            .then(resp => {
              // this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.d_algorithmSearch.loading = false;
              if (resp.data.status_code === '1000') {
                this.d_algorithmSearch.search_list = resp.data.search_list;
                if (this.d_algorithmSearch.search_list.length === 0) {
                  this.d_showNoSearchResult = true;
                }
                if (this.d_chatBotType === 'search_and_add') {
                  if (this.d_algorithmSearch.search_list.length > 0) {
                    let chatbot_talk = {
                      'message': 'Lütfen seçiminizi yapınız.',
                      'talk_type': 'chatbot'
                    };
                    this.d_talkList.push(chatbot_talk);
                  }
                  let after_finding = this.d_algorithmSearch.search_list.length;
                  // this.f_getCursorPosition();
                  // console.log('caret_coordinates ', caret_coordinates);
                  let context_menu_popup = document.getElementById('context-menu-popup');
                  // let position = this.F_getCaretIndex(div_el_wdmr_sentence_template);
                  // console.log('position ', position);
                  // let charCount = this.f_getCurrentCursorPosition('wdmr_sentence_template');
                  // console.log('charCount ::::: ', charCount);
                  console.log("context_menu_popup : ", context_menu_popup);
                  console.log("caret_coordinates  : ", caret_coordinates);
                  console.log("after_finding      : ", after_finding);
                  /*
                   */
                  if (after_finding === 0) {
                    this.d_activeKeyDownText = '';
                    // context_menu_popup.style.left = '0px';
                    // context_menu_popup.style.top = '0px';
                    if (context_menu_popup) {
                      context_menu_popup.style.display = 'none';
                    }
                  } else {
                    if (context_menu_popup) {
                      context_menu_popup.style.display = '';
                    }
                    /*
                      if (caret_coordinates.x !== 0 && caret_coordinates.y !== 0) {
                        context_menu_popup.style.left = (caret_coordinates.x).toString() + 'px';
                        context_menu_popup.style.top = (caret_coordinates.y + 10).toString() + 'px';
                        context_menu_popup.style.display = '';
                      }
                    */
                  }
                }
              } else {
                this.d_showNoSearchResult = true;
                let msg = '';
                msg = resp.data.status_message;
                let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': '' };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
                this.$forceUpdate();
              }
            });
        }
      }
    },
    f_getCaretCoordinates: function () {
      let x = 0;
      let y = 0;
      let isSupported = typeof window.getSelection !== "undefined";
      if (isSupported) {
        let selection = window.getSelection();
        if (selection.rangeCount !== 0) {
          let range = selection.getRangeAt(0).cloneRange();
          range.collapse(true);
          let rect = range.getClientRects()[0];
          if (rect) {
            x = rect.left;
            y = rect.top;
          }
        }
      }
      return { x, y };
    },
    f_filterAlgorithm: function (algorithm) {
      if (!this.d_algorithmSearch.text) {
        return true;
      } else {
        if (algorithm.message) {
          if (algorithm.message.toLowerCase().indexOf(this.d_algorithmSearch.text.toLowerCase()) !== -1) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    },
    f_deleteSearchText: function () {
      this.d_algorithmSearch.text = '';
      this.d_algorithmSearch.search_list = [];
      this.$forceUpdate();
    },
    f_showAlgorithmInfo: function (algorithm) {
      this.d_algorithmInfo.algorithm = algorithm;
      this.d_algorithmInfo.show = true;
    },
    f_selectDmpLanguage: function () {
      if (this.d_selectedDmpWdmrData.data && this.d_selectedDmpWdmrData.data.general && this.d_selectedDmpWdmrData.data.general.algorithm_language_list && this.d_selectedDmpWdmrData.data.general.algorithm_language_list.val && this.d_selectedDmpWdmrData.data.general.algorithm_language_list.val.length > 0 && this.d_selectedDmpWdmrData.data.general.algorithm_default_language && this.d_selectedDmpWdmrData.data.general.algorithm_default_language.val && this.d_selectedDmpWdmrData.data.general.algorithm_default_language.val.value) {
        this.d_languageList = this.d_selectedDmpWdmrData.data.general.algorithm_language_list.val;
        let target_lang_value = this.d_selectedDmpWdmrData.data.general.algorithm_default_language.val.value;
        let route_lang = '';
        if (this.$route.query.lang) {
          route_lang = this.$route.query.lang.toLowerCase();
        }
        // console.log('route_lang : ', route_lang);
        if (route_lang) {
          if (this.d_langMatch[route_lang] !== undefined) {
            target_lang_value = route_lang;
          } else {
            for (let lang_value in this.d_langMatch) {
              if (this.d_langMatch[lang_value].indexOf(route_lang) !== -1) {
                target_lang_value = lang_value;
                break;
              }
            }
          }
        }
        // console.log('target_lang_value : ', target_lang_value);
        for (let i in this.d_languageList) {
          if (this.d_languageList[i].value === target_lang_value) {
            this.d_selectedLanguageIndex = parseInt(i);
            break
          }
        }
      }
    },
    f_controlAndGetDmpByThisToken: function () {
      let query = "dmp_id=" + this.$route.query.dmp_id;
      query += '&chatbot_token=' + this.d_chatBotDiagram.chatbot_token;
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz' } });
      ChatBotService.control_and_get_dmp_by_this_token(query)
        .then(resp => {
          // this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '1000') {
            this.d_selectedDmpWdmrData = resp.data.dmp;
            try {
              this.d_chatBotType = this.d_selectedDmpWdmrData.data.general.chatbot_type.val.value;
            } catch (err) {}
            if (this.d_chatBotType === 'search_and_add') {
              let chatbot_talk = {
                'message': 'Merhaba',
                'talk_type': 'chatbot'
              };
              this.d_talkList.push(chatbot_talk);
              chatbot_talk = {
                'message': 'Size yardımcı olabilmem için lütfen şikayetlerinizi belirtiniz.',
                'talk_type': 'chatbot'
              };
              this.d_talkList.push(chatbot_talk);
            }
            if (this.d_selectedDmpWdmrData && this.d_selectedDmpWdmrData.data && this.d_selectedDmpWdmrData.data.general && this.d_selectedDmpWdmrData.data.general.language_icon_in_wchatbot_screen && this.d_selectedDmpWdmrData.data.general.language_icon_in_wchatbot_screen.val && this.d_selectedDmpWdmrData.data.general.language_icon_in_wchatbot_screen.val.value) {
              if (this.d_selectedDmpWdmrData.data.general.language_icon_in_wchatbot_screen.val.value === 'yes') {
                this.d_languageIconInWchatbotScreen = true;
              } else {
                this.d_languageIconInWchatbotScreen = false;
              }
            } else {
              this.d_languageIconInWchatbotScreen = true;
            }
            this.f_selectDmpLanguage();
            this.f_chatBotDiagramAnalyze();
            // console.log(this.d_selectedDmpWdmrData);
            let dmp_key = 'wdm24_' + this.$route.query.dmp_id;
            let parent_wdm = {
              'key': dmp_key,
              'bucket': 'wisdom',
              'type': 'wdm24'
            };
            let child_wdm = {
              'bucket': 'wisdom',
              'type': 'wdm308'
            };
            let relation_type = 'keys';
            // console.log('wdm308');
            ClsWdmrManage.get_related_wdmr_list(parent_wdm, child_wdm, relation_type)
              .then(resp => {
                // this.$store.commit('Loading', { 'status': false, 'data': {} });
                if (resp && resp.list && resp.list.length > 0) {
                  let data = { 'key': resp.list[0].key, 'bucket': 'wisdom' };
                  GlobalService.get_one_cb_document(data)
                    .then(resp => {
                      // console.log(resp.data);
                      if (resp.data.status === "success") {
                        this.d_selectedWdm308ApiDocument = resp.data.result;
                      } else {
                        console.log(resp.data.message);
                        // alert(resp.data.message);
                      }
                    });
                }
              }, resp => {
                console.log('error ', resp);
              });

          } else {
            let msg = '';
            msg = resp.data.status_message;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': '' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            this.$forceUpdate();
          }
        });
    },
    f_startDecisionTree: function () {
      this.d_startDecisionSupport = true;
      this.d_chatBotDiagram.diagram_point = '';
      this.d_algorithmSearch.search_list = [];
      this.d_chatBotDiagram.algorithm_list = [];
      this.d_algorithmSearch.text = '';
      this.d_selectedAlgorithmList = [];
      this.d_talkList = [];
      this.d_selectedSearchAlgorithmList = [];
      if (this.d_chatBotType === 'search_and_add') {
        let context_menu_popup = document.getElementById('context-menu-popup');
        if (context_menu_popup) {
          context_menu_popup.style.display = 'none';
        }
        let chatbot_talk = {
          'message': 'Merhaba',
          'talk_type': 'chatbot'
        };
        this.d_talkList.push(chatbot_talk);
        chatbot_talk = {
          'message': 'Size yardımcı olabilmem için lütfen şikayetlerinizi belirtiniz.',
          'talk_type': 'chatbot'
        };
        this.d_talkList.push(chatbot_talk);
      } else {
        this.f_chatBotDiagramAnalyze();
      }
    },
    f_calculateMenuStyle: function () {
      let style = '';
      style += 'background: ' + this.d_menuStyleList[this.d_selectedMenuStyle].background + ';';
      style += 'width: ' + this.d_menuStyleList[this.d_selectedMenuStyle].width + ';';
      if (this.d_menuStyleList[this.d_selectedMenuStyle].position) {
        style += 'position: ' + this.d_menuStyleList[this.d_selectedMenuStyle].position + ';';
      }
      if (this.d_menuStyleList[this.d_selectedMenuStyle].top) {
        style += 'top: ' + this.d_menuStyleList[this.d_selectedMenuStyle].top + 'px;';
      }
      if (this.d_menuStyleList[this.d_selectedMenuStyle].bottom) {
        style += 'bottom: ' + this.d_menuStyleList[this.d_selectedMenuStyle].bottom + 'px;';
      }
      if (this.d_menuStyleList[this.d_selectedMenuStyle].margin) {
        style += 'margin: ' + this.d_menuStyleList[this.d_selectedMenuStyle].margin + 'px;';
      }
      return style;
    },
    f_infoDmp: function () {
      this.d_startDecisionSupport = false;
    },
    f_changeStyle: function () {
      if (this.d_selectedStyleIndex !== this.d_styleList.length - 1) {
        this.d_selectedStyleIndex += 1;
      } else {
        this.d_selectedStyleIndex = 0;
      }
      if (this.$route.query.style === undefined || (parseInt(this.$route.query.style) !== this.d_selectedStyleIndex)) {
        let route_data = {
          // 'name': 'algorithmdevelopment',
          // 'path': '/rule-engine/algorithm',
          'query': {
            'dmp_id': this.$route.query.dmp_id,
            'style': this.d_selectedStyleIndex
          }
        };
        if (Object.keys(this.d_clientVariables).length > 0) {
          for (let i in this.d_clientVariables) {
            route_data.query[i] = this.d_clientVariables[i];
          }
        }
        if (this.$route.query.chatbot_token !== undefined) {
          route_data.query.chatbot_token = this.$route.query.chatbot_token;
        }
        this.$router.push(route_data);
      }
      this.$forceUpdate();
    },
    f_changeMenuStyle: function () {
      if (this.d_selectedMenuStyle !== this.d_menuStyleList.length - 1) {
        this.d_selectedMenuStyle += 1;
      } else {
        this.d_selectedMenuStyle = 0;
      }
      if (this.$route.query.menustyle === undefined || (parseInt(this.$route.query.menustyle) !== this.d_selectedMenuStyle)) {
        let route_data = {
          // 'name': 'algorithmdevelopment',
          // 'path': '/rule-engine/algorithm',
          'query': {
            'dmp_id': this.$route.query.dmp_id,
            'style': this.d_selectedStyleIndex,
            'menustyle': this.d_selectedMenuStyle
          }
        };
        if (Object.keys(this.d_clientVariables).length > 0) {
          for (let i in this.d_clientVariables) {
            route_data.query[i] = this.d_clientVariables[i];
          }
        }
        if (this.$route.query.chatbot_token !== undefined) {
          route_data.query.chatbot_token = this.$route.query.chatbot_token;
        }
        this.$router.push(route_data);
      }
      this.$forceUpdate();
    },
    f_calculateAlgorithmStyle: function (algorithm = '') {
      let style = 'cursor: pointer; margin-bottom: 5px; margin-left: 10px; margin-right: 10px;min-height: 50px; vertical-align: middle;';
      let background = '';
      if (algorithm && algorithm.point === this.d_chatBotDiagram.diagram_point) {
        style += 'background: ' + this.d_styleList[this.d_selectedStyleIndex].algorithm.background_selected + ';';
      } else {
        style += 'background: ' + this.d_styleList[this.d_selectedStyleIndex].algorithm.background + ';';
      }
      return style;
    },
    f_calculateStyleWindow: function () {
      // let device_control_style = 'margin-bottom: 30px;';
      let device_control_style = '';
      if (!this.device.isMobile) {
        let one_part_of_three = 10;
        if (this.d_screenMode === 'fullscreen') {
          //
        } else if (this.d_screenMode === 'middle') {
          one_part_of_three = window.innerWidth / 3;
        }
        device_control_style += 'margin-top: 30px; margin-left: ' + one_part_of_three.toString() + 'px; margin-right: ' + one_part_of_three.toString() + 'px;';
      } else {
        // let one_part_of_ten = window.innerWidth / 10;
        device_control_style += 'margin-top: 30px; margin-left: 10px; margin-right: 10px;';
      }
      let background = this.d_styleList[this.d_selectedStyleIndex].screen.background;
      let style = '';
      let h = window.innerHeight;
      style = 'height: ' + h + 'px; ' + 'background: ' + background + ';' + device_control_style;
      return style;
    },
    f_chatBotDiagramBack: function () {
      let point_list = this.d_chatBotDiagram.last_diagram_point.split(',');
      // console.log(point_list);
      if (point_list.length === 1) {
        this.d_chatBotDiagram.diagram_point = '';
      } else {
        point_list.splice(point_list.length - 1, 1);
        this.d_chatBotDiagram.diagram_point = point_list.join(',');
      }
      // console.log(this.d_chatBotDiagram.diagram_point);
      this.f_chatBotDiagramAnalyze();
      this.d_selectedAlgorithmList.splice(this.d_selectedAlgorithmList.length - 1, 1);
    },
    f_selectDiagramPoint: function (diagram_point) {
      this.d_chatBotDiagram.diagram_point = diagram_point;
      this.$forceUpdate();
    },
    f_selectDiagramPointAndGo: function (algorithm) {
      // console.log("algorithm", algorithm);
      /*
      {
        id: "36"
        message: "Kilo 50 den az, karar evde takip"
        message_image: "data:image/png;base64,iVBORw0KGg.....",
        name: "Kilo 50den fazla"
        next: 1
        operation_id: "50_ev"
        point: "0
      }
      */
      if (!this.d_chatBotDiagram.loading) {
        let sel_alg = { 'name': algorithm.name, 'id': algorithm.id, 'message': algorithm.message };
        this.d_selectedAlgorithmList.push(sel_alg);
        this.d_chatBotDiagram.diagram_point = algorithm.point;
        this.d_chatBotDiagram.selected_algorithm = algorithm;
        this.$forceUpdate();
        this.f_chatBotDiagramAnalyze();
      }
    },
    f_selectSearchedDiagramPointAndGo: function (search_algorithm) {
      // console.log("algorithm", algorithm);
      /*
      {
        diagram_point: "0,1,1",
        algorithm: {'label'}
      }
      */
      for (let i in search_algorithm.branch_algorithm_list) {
        this.d_selectedAlgorithmList.push(search_algorithm.branch_algorithm_list[i]);
      }
      this.d_chatBotDiagram.diagram_point = search_algorithm.diagram_point;
      this.d_chatBotDiagram.selected_algorithm = search_algorithm.algorithm;
      this.$forceUpdate();
      this.f_chatBotDiagramAnalyze();
    },
    f_chatBotDiagramAnalyze: function () {
      // console.log('f_chatBotDiagramAnalyze');
      let query = "dmp_id=" + this.d_selectedDmpWdmrData.id;
      if (this.d_chatBotDiagram.diagram_point) {
        query += '&diagram_point=' + this.d_chatBotDiagram.diagram_point;
      }
      if (this.$route.query.chatbot_token) {
        query += '&chatbot_token=' + this.$route.query.chatbot_token;
      }
      if (this.d_selectedLanguageIndex !== '' && this.d_languageList.length > 0) {
        query += '&lang=' + this.d_languageList[this.d_selectedLanguageIndex].value;
      }
      // console.log('query', query);
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz' } });
      this.d_chatBotDiagram.loading = true;
      this.d_sandWatch.loading = true;
      ChatBotService.chatbot_talk_diagram(query)
        .then(resp => {
          this.d_sandWatch.loading = false;
          this.d_chatBotDiagram.loading = false;
          // this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '1000') {
            this.d_algorithmSearch.text = '';
            this.d_algorithmSearch.search_list = [];
            this.d_chatBotDiagram.last_diagram_point = this.d_chatBotDiagram.diagram_point;
            this.d_chatBotDiagram.algorithm_list = resp.data.algorithm_list;
            this.d_algorithmSearch.search_list = resp.data.algorithm_list;
            this.d_chatBotDiagram.show = true;
            // console.log('d_chatBotDiagram');
            // console.log(this.d_chatBotDiagram);
            if (this.d_chatBotDiagram.algorithm_list.length === 0) {
              // Here we understand we are at the end of the diagram. And clicked the last diagram point
              if (this.d_selectedWdm308ApiDocument && this.d_selectedWdm308ApiDocument.id) {
                this.f_apiRuleOperation();
              }
            } else {
              /*
                setTimeout(function () {
                  let div_html = document.getElementById('search_text_algorithm');
                  if (div_html) {
                    div_html.focus();
                  }
                }.bind(this), 500)
              */
            }
          } else {
            let msg = '';
            msg = resp.data.status_message;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': '' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            this.$forceUpdate();
          }
        });
    },
    f_apiRuleOperation: function () {
      let true_algorithm_response_type = '';
      try {
        true_algorithm_response_type = this.d_selectedDmpWdmrData.data.general.true_algorithm_response_type.val.value;
      } catch (err) {}
      if (true_algorithm_response_type === 'send_defined_service') {
        this.d_sandWatch.loading = true;
        let query = '';
        query += 'dmp_id=' + this.d_selectedDmpWdmrData.id;
        query += '&diagram_point=' + this.d_chatBotDiagram.diagram_point;
        query += '&algorithm_id=' + this.d_chatBotDiagram.selected_algorithm.id;
        query += '&api_id=' + this.d_selectedWdm308ApiDocument.id;
        if (this.$route.query.chatbot_token !== undefined) {
          query += '&chatbot_token=' + this.$route.query.chatbot_token;
        }
        let data = {
          'client_list': {},
          'selected_algorithm_list': this.d_selectedAlgorithmList
        };
        if (this.d_clientVariables) {
          data.client_list = this.d_clientVariables;
        }
        // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz' } });
        this.d_apiOperationIsContinuing = true;
        ChatBotService.api_rule_operation(query, data)
          .then(resp => {
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status_code === '1000') {
              // console.log(resp.data);
              setTimeout(function () {
                this.d_sandWatch.loading = false;
                this.d_apiOperationIsContinuing = false;
                // this.d_chatBotDiagram.diagram_point = '';
                // this.d_selectedAlgorithmList = [];
                // this.f_chatBotDiagramAnalyze();
                // msg = resp.data.status_message;
              }.bind(this), 2000)
            } else {
              msg = resp.data.status_message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': '' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
            this.$forceUpdate();
          });
      }
    },
    f_getUserDmpList: function () {
      let parent_wdm = { 'label': this.wdm22.label, 'key': 'wdm22_' + this.wdm22.id, 'bucket': 'wisdom', 'type': 'wdm22' };
      let child_wdm = { 'bucket': 'wisdom', 'type': 'wdm24' };
      let relation_type = 'keys';
      let data = {
        'parent_wdm': parent_wdm,
        'child_wdm': child_wdm,
        'relation_type': relation_type
      };
      // console.log(data);
      WdmService.get_related_wdmr_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_dmpList = resp.data.result;
            if (!this.d_dmpList.list) {
              this.d_dmpList.list = [];
            }
            // console.log('this.$route', this.$route);
            for (let i in this.d_dmpList.list) {
              if (this.d_dmpList.list[i].key === 'wdm24_' + this.$route.query.dmp_id) {
                this.f_selectDmp(parseInt(i), 'wdm24_' + this.$route.query.dmp_id);
                break;
              }
            }
          } else {
            alert('errorr \n', resp.data.message);
          }
        });
    },
    f_selectDmp: function (dmp_index, dmp_key) {
      console.log("dmp_index: ", dmp_index);
      // console.log("dmp_key: ", dmp_key);
      if (!this.d_selectedDmpWdmrData || (this.d_selectedDmpWdmrData && 'wdm24_' + this.d_selectedDmpWdmrData.id !== dmp_key)) {
        // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
        this.d_selectedWdmrIndex_dmp = dmp_index;
        if (this.$route.query && this.$route.query.dmp_id !== dmp_key.split('_')[1]) {
          let route_data = {
            // 'name': 'algorithmdevelopment',
            // 'path': '/rule-engine/algorithm',
            'query': {
              'dmp_id': dmp_key.split('_')[1]
            }
          };
          if (Object.keys(this.d_clientVariables).length > 0) {
            for (let i in this.d_clientVariables) {
              route_data.query[i] = this.d_clientVariables[i];
            }
          }
          if (this.$route.query.chatbot_token !== undefined) {
            route_data.query.chatbot_token = this.$route.query.chatbot_token;
          }
          this.$router.push(route_data);
        }
        // console.log("this.f_getWdmrData(dmp_key) ");
      }
      this.f_getDmpData(dmp_key);
    },
    f_getDmpData: function (dmp_key) {
      // console.log('dmp_key ..................... ');
      let bucket = 'wisdom';
      let data = { 'key': dmp_key, 'bucket': bucket };
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document(data)
        .then(resp => {
          // console.log(resp.data);
          if (resp.data.status === "success") {
            this.d_selectedDmpWdmrData = resp.data.result;
            this.f_selectDmpLanguage();
            this.f_chatBotDiagramAnalyze();
            // console.log(this.d_selectedDmpWdmrData);
            let parent_wdm = {
              'key': dmp_key,
              'bucket': 'wisdom',
              'type': 'wdm24'
            };
            let child_wdm = {
              'bucket': 'wisdom',
              'type': 'wdm308'
            };
            let relation_type = 'keys';
            // console.log('wdm308');
            ClsWdmrManage.get_related_wdmr_list(parent_wdm, child_wdm, relation_type)
              .then(resp => {
                // this.$store.commit('Loading', { 'status': false, 'data': {} });
                if (resp && resp.list && resp.list.length > 0) {
                  data = { 'key': resp.list[0].key, 'bucket': 'wisdom' };
                  GlobalService.get_one_cb_document(data)
                    .then(resp => {
                      // console.log(resp.data);
                      if (resp.data.status === "success") {
                        this.d_selectedWdm308ApiDocument = resp.data.result;
                      } else {
                        alert(resp.data.message);
                      }
                    });
                }
              }, resp => {
                console.log('error ', resp);
              });
          } else {
            alert(resp.data.message);
          }
        });
    },
  },
  watch: {
    'd_algorithmSearch.text': function () {
      this.d_algorithmSearch.search_list = [];
      this.d_showNoSearchResult = false;
      if (this.d_chatBotType === 'search_and_add') {
        if (!this.d_algorithmSearch.text) {
          let context_menu_popup = document.getElementById('context-menu-popup');
          if (context_menu_popup) {
            context_menu_popup.style.display = 'none';
          }
        }
        if (this.d_algorithmSearch.timeout) {
          clearTimeout(this.d_algorithmSearch.timeout);
          this.d_algorithmSearch.timeout = 0;
        }
        if (this.d_algorithmSearch.text.length >= 1) {
          this.d_algorithmSearch.timeout = setTimeout(function () {
            this.f_searchInDiagram();
          }.bind(this), 1000);
        }
      }
    }
  }
};

</script>

<style type="text/css">
.table-row-directory {
  border-bottom: solid 1px #e6e6e6;
}

.table-row-directory:hover {
  background: #ddfffd;
}

#class-context-menu-popup {
  width: 100%;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  z-index: 1000;
  background-color: #d8d8d8;
  box-shadow: 1px -1px 2px 0px black;
  /*border: solid 1px #11c0be;*/
  margin-left: 10px;
  padding-left: 10px;
}

#class-context-menu-popup::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#class-context-menu-popup::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

</style>

