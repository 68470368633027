<template>
  <div>
    <template>
      <b-card no-body header-tag="header" footer-tag="footer" border-variant="primary" class="mb-1">
        <b-card-header header-bg-variant="primary" class="p-1" style="cursor: pointer;">
          <b-row>
            <b-col cols="3" v-b-toggle="'wdh_ai_robot'">
              <strong>{{ $t('wdm16.13140') }}</strong>
            </b-col>
            <b-col cols="4" v-b-toggle="'wdh_ai_robot'">
              <div v-if="d_waitingForSpeechResponse" style="color: white;">{{ $t('wdm16.13141') }}</div>
              <div v-if="d_sttResult.text" style="color: white;"> {{ d_sttResult.text + ' (' + d_sttResult.confidence + ')' }}</div>
            </b-col>
            <b-col cols="2">
              <template v-if="false">
                min: {{ d_byteMin }} {{ $t('wdm16.13143') }} {{ d_byteMax }}
                <br> {{ $t('wdm16.13144') }} {{ d_talkStarted }}
                <br> {{ $t('wdm16.13145') }} {{ d_talkEnded }}
                <br> {{ $t('wdm16.13146') }} {{ d_intervalTalkingTimeMs }}
                <br> {{ $t('wdm16.13147') }} {{ d_intervalStopTalkTimeMs }}
                <br> {{ $t('wdm16.13148') }} {{ d_totalStartCount }}
                <br>
              </template>
              <b-form-select v-model="d_selectedLang">
                <option value="en-US">en-US</option>
                <option value="tr-TR">tr-TR</option>
              </b-form-select>
            </b-col>
            <b-col cols="3">
              <b-button v-if="d_recordingStarted" size="md" title="Sesli komut" variant="secondary" @click="f_startRecording()" style="padding: 1px; margin-right: 5px;" class="pull-right">
                <img src="@/icon/2524823.png" style="width: 1.5em;" /> {{ $t('wdm16.1298') }}
              </b-button>
              <b-button v-if="d_recordingPaused" size="md" title="Sesli komut" variant="secondary" @click="f_pauseRecording()" style="padding: 1px; margin-right: 5px;" class="pull-right">
                <img src="@/icon/2524823.png" style="width: 1.5em;" /> {{ $t('wdm16.13149') }}
              </b-button>
              <b-button v-if="d_recordingStopped" size="md" title="Sesli komut" variant="secondary" @click="f_stopRecording()" style="padding: 1px; margin-right: 5px;" class="pull-right">
                <img src="@/icon/2524823.png" style="width: 1.5em;" /> {{ $t('wdm16.13150') }}
              </b-button>
              <canvas id="draw_audio_canvas" style="background-color: transparent; height: 30px;"></canvas>
              <!-- <span v-if="d_youAreTalking" style="color: red">Konuşuluyor</span> -->
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse :visible="true" id="wdh_ai_robot">
          <b-row style="margin: 2px;">
            <b-col sm="12" lg="2">
              <b-form-select v-model="d_analyzeType">
                <option value="match_param_option">{{ $t('wdm16.13151') }}</option>
                <option value="nlp">{{ $t('wdm16.13152') }}</option>
              </b-form-select>
            </b-col>
            <b-col sm="12" lg="8">
              <b-textarea ref="text_analyze" v-model="d_textAnalyze"></b-textarea>
              <div v-if="d_errorInText" style="color: red;">{{ $t('wdm16.13153') }}</div>
            </b-col>
            <b-col sm="12" lg="2">
              <b-button size="md" title="Analiz Yap" variant="secondary" @click="f_analyzeTextForWdm()" style="padding: 1px; height: 100%; width: 100%;">
                <img src="@/icon/2324511.png" style="width: 3em;" />
              </b-button>
            </b-col>
          </b-row>
          <div id="recordingsList"></div>
        </b-collapse>
      </b-card>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WdmService from '@/services/wdm'
import FileSaver from 'file-saver';

export default {
  name: 'SpeechToText',
  computed: {
    ...mapGetters({
      lang: 'lang',
      help: 'help'
    })
  },
  props: {
    p_speechResults: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      myRecorder: '',
      d_talkingIntervalStartObj: '',
      d_talkingIntervalEndObj: '',
      d_intervalTalkingTimeMs: 0,
      d_intervalStopTalkTimeMs: 0,
      d_notTalkIntervalStartObj: '',
      d_notTalkIntervalEndObj: '',
      d_intervalTalkStartedObj: 0,
      d_intervalTalkEndedObj: 0,
      d_waitingForSpeechResponse: false,
      d_sttResult: { 'confidence': '', 'text': '' },
      d_totalStartCount: 0,
      d_byteMin: '',
      d_byteMax: '',
      d_talkStarted: '',
      d_talkEnded: '',
      d_talkDifferenceMs: 0,
      d_youAreTalking: false,
      dataArrayList: [],
      animationFrame: '',
      interval: '',
      d_recordingStarted: true,
      d_recordingPaused: false,
      d_recordingStopped: false,
      d_textAnalyze: '',
      d_correctTextList: [],
      d_analyzeType: 'match_param_option', // match_all
      d_errorInText: '',
      d_selectedLang: 'tr-TR'
    };
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  beforeDestroy () {
    if (this.myRecorder) {
      this.myRecorder.stop();
      this.gumStream.getAudioTracks()[0].stop();
    }
  },
  mounted () {},
  methods: {
    f_createDownloadLink (blob) {
      // console.log(blob);
      var url = URL.createObjectURL(blob);
      console.log(url);
      var au = document.createElement('audio');
      var li = document.createElement('li');
      var link = document.createElement('a');

      //name of .wav file to use during upload and download (without extendion)
      var filename = new Date().toISOString();

      //add controls to the <audio> element
      au.controls = true;
      au.src = url;

      //save to disk link
      link.href = url;
      link.download = filename + ".wav"; //download forces the browser to donwload the file using the  filename
      link.innerHTML = "Save to disk";

      //add the new audio element to li
      li.appendChild(au);

      //add the filename to the li
      li.appendChild(document.createTextNode(filename + ".wav "))

      //add the save to disk link to li
      li.appendChild(link);

      //upload link
      var upload = document.createElement('a');
      upload.href = "#";
      upload.innerHTML = "Upload";
      /*      upload.addEventListener("click", function (event) {
              var xhr = new XMLHttpRequest();
              xhr.onload = function (e) {
                if (this.readyState === 4) {
                  console.log("Server returned: ", e.target.responseText);
                }
              };
              var fd = new FormData();
              fd.append("audio_data", blob, filename);
              xhr.open("POST", "upload.php", true);
              xhr.send(fd);
            })*/
      li.appendChild(document.createTextNode(" ")) //add a space in between
      li.appendChild(upload) //add the upload link to li

      //add the li element to the ol
      recordingsList.appendChild(li);
    },
    f_stopRecording: function () {
      this.d_waitingForSpeechResponse = false;
      this.f_clearAiRobotMatchParameterResult();
      // this.node.disconnect();
      // this.input.disconnect();
      // cancelAnimationFrame(this.anim);
      cancelAnimationFrame(this.animationFrame);
      // this.f_fileSave(this.dataArrayList);
      console.log("stopButton clicked");
      // clearInterval(this.interval);
      //disable the stop button, enable the record too allow for new recordings
      this.d_recordingStopped = false;
      this.d_recordingStarted = true;
      this.d_recordingPaused = false;

      //tell the recorder to stop the recording
      this.myRecorder.stop();

      //stop microphone access
      this.gumStream.getAudioTracks()[0].stop();

      //create the wav blob and pass it on to createDownloadLink
      // this.myRecorder.exportWAV(this.f_createDownloadLink);
    },
    f_pauseRecording: function () {
      console.log("pauseButton clicked rec.recording=", this.myRecorder.recording);
      if (this.myRecorder.recording) {
        //pause
        this.myRecorder.stop();
      } else {
        //resume
        this.myRecorder.record()
      }
    },
    f_startRecording: function () {
      console.log("recordButton clicked");
      var constraints = { audio: true, video: false }
        /*
            We're using the standard promise based getUserMedia() 
            https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
        */
      this.d_recordingStopped = true;
      this.d_recordingStarted = false;
      this.d_recordingPaused = true;
      this.d_youAreTalking = false;
      let vm = this;
      navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
        console.log("getUserMedia() success, stream created, initializing Recorder.js ...");
        var gumStream; //stream from getUserMedia()
        var myRecorder; //Recorder.js object
        var input; //MediaStreamAudioSourceNode we'll be recording
        var node;
        var analyser;
        var AudioContext = window.AudioContext || window.webkitAudioContext;
        var audioContext = new AudioContext({ sampleRate: 44100 });
        var anim;
        var canvas = document.getElementById("draw_audio_canvas");
        var canvasCtx = canvas.getContext("2d");
        node = audioContext.createScriptProcessor(4096, 2, 2);
        analyser = audioContext.createAnalyser();
        // console.log(analyser);
        analyser.fftSize = 2048;
        var bufferLength = analyser.frequencyBinCount;
        var dataArray = new Uint8Array(bufferLength);
        // console.log('dataArray : ', dataArray);
        analyser.getByteTimeDomainData(dataArray);
        var microphone = audioContext.createMediaStreamSource(stream);
        microphone.connect(analyser);
        var d_talkStartedObj;
        var d_talkEndedObject;

        // input.connect(analyser);
        //****************************
        function file_read(blob) {
          var reader = new FileReader();
          reader.addEventListener("load", function () {
            // console.log(reader.result);
            vm.f_analyzeTextForWdm(reader.result);
          }, false);
          reader.readAsDataURL(blob);
        }

        function draw_audio_canvas() {
          // anim = requestAnimationFrame(draw_audio_canvas);
          // console.log('.');
          // vm.dataArrayList = vm.dataArrayList.concat(dataArray);
          if (vm.myRecorder.recording) {
            console.log(dataArray);
            // console.log(Math.min(...dataArray), ' | ', Math.max(...dataArray));
            vm.animationFrame = requestAnimationFrame(draw_audio_canvas);
            if (!vm.d_waitingForSpeechResponse) {
              if (Math.min(...dataArray) < 125) { // => TALK STARTED
                if (!vm.d_talkingIntervalStartObj) {
                  vm.d_talkingIntervalStartObj = new Date();
                } else {
                  vm.d_talkingIntervalEndObj = new Date();
                  vm.d_intervalTalkingTimeMs += vm.d_talkingIntervalEndObj.getTime() - vm.d_talkingIntervalStartObj.getTime();
                }
                vm.d_notTalkIntervalStartObj = '';
                vm.d_intervalStopTalkTimeMs = 0;
                if (!vm.d_youAreTalking) {
                  // If you use clear method after exporting wav, it means you will get the sound from the last time of vm.d_youAreTalking = true.
                  /*
                    vm.d_byteMin = Math.min(...dataArray);
                    vm.d_byteMax = Math.max(...dataArray);
                  */
                  vm.myRecorder.clear();
                  vm.d_totalStartCount += 1;
                  vm.d_youAreTalking = true;
                  d_talkStartedObj = new Date();
                  vm.d_talkStarted = new Date().toISOString();
                }
              } else if (Math.min(...dataArray) > 125) { // TALK STOPPED or DID NOT START YET.
                if (vm.d_youAreTalking) { // If user started talking a while ago and now user is waiting.
                  vm.d_talkingIntervalStartObj = '';
                  if (!vm.d_notTalkIntervalStartObj) {
                    vm.d_notTalkIntervalStartObj = new Date();
                  } else {
                    vm.d_notTalkIntervalEndObj = new Date();
                    vm.d_intervalStopTalkTimeMs = vm.d_notTalkIntervalEndObj.getTime() - vm.d_notTalkIntervalStartObj.getTime();
                  }
                  // d_talkEndedObject = new Date();
                  // In the below code, real difference is saving on time_dif.
                  // let time_dif = d_talkEndedObject.getTime() - d_talkStartedObj.getTime();
                  // We dont want to make requests for small audios. So we control this by vm.d_talkDifferenceMs value. Minimum of .....(300).
                  // total talking time will be more than 500 and wait after last talk will be more than 500 ms.
                  let wait = 500;
                  if (vm.d_intervalTalkingTimeMs > 5000) {
                    if (vm.d_analyzeType === 'nlp') {
                      wait = 1500;
                    }
                    if (vm.d_intervalStopTalkTimeMs > wait) {
                      vm.d_intervalStopTalkTimeMs = 0;
                      vm.d_intervalTalkingTimeMs = 0;
                      vm.d_youAreTalking = false;
                      // console.log('time_dif: ', time_dif);
                      vm.d_byteMin = Math.min(...dataArray);
                      vm.d_byteMax = Math.max(...dataArray);
                      vm.d_talkEnded = new Date().toISOString();
                      // console.log(d_talkStartedObj.getTime());
                      // console.log(d_talkEndedObject.getTime());
                      // If exportWav like this method. You get all the recorded data from the very beginnig.
                      // We control the real start to end time difference. If it is more than .... ms, we send a request to server, then to speech to text engine.
                      vm.myRecorder.exportWAV(file_read);
                      // vm.myRecorder.exportWAV(vm.f_createDownloadLink);
                    }
                  } else { // user did not talk enough time but waiting time is bigger than ....(500)
                    if (vm.d_intervalStopTalkTimeMs > 1000) {
                      vm.d_intervalTalkingTimeMs = 0;
                      vm.d_youAreTalking = false;
                      // console.log('time_dif: ', time_dif);
                      vm.d_byteMin = Math.min(...dataArray);
                      vm.d_byteMax = Math.max(...dataArray);
                      vm.d_talkEnded = new Date().toISOString();
                    }
                  }
                }
              }
            } else {
              vm.d_youAreTalking = false;
            }
            analyser.getByteTimeDomainData(dataArray);
            canvasCtx.fillStyle = "rgb(32, 168, 216)";
            canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
            canvasCtx.lineWidth = 5;
            canvasCtx.strokeStyle = "rgb(255, 255, 255)";
            canvasCtx.beginPath();
            // sliceWidth means: We devide the width for every buffer point in dataArray with bufferLength. So while drawing x, y line, in for loop,
            // we add x this sliceWidth for every next (x,y) points
            var sliceWidth = canvas.width * 1.0 / bufferLength;
            var x = 0;
            for (var i = 0; i < bufferLength; i++) {
              var v = dataArray[i] / 128.0;
              // console.log(v);
              // y is the height location of point. if v that is a buffer point in dataarray divided by 128 is equal to 1, it means
              // The x, y point will on the middle of vertical canvas line.
              var y = v * canvas.height / 2;
              if (i === 0) {
                canvasCtx.moveTo(x, y);
              } else {
                canvasCtx.lineTo(x, y);
                // console.log(y)
              }
              x += sliceWidth;
              // console.log(x, y);
            }
            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
          }
        }
        vm.d_recordingStarted = false;
        vm.gumStream = stream;
        vm.input = audioContext.createMediaStreamSource(stream);
        vm.myRecorder = new Recorder(vm.input, { numChannels: 1 });
        vm.myRecorder.record();
        console.log("Recording started");
        draw_audio_canvas();
        /*
        vm.interval = setInterval(() => {
          // console.log('test');
          draw_audio_canvas();
        }, 50);
        */
      }).catch(function (err) {
        console.log(err);
      });

    },
    f_clearAiRobotMatchParameterResult: function () {
      this.p_aiRobotMatchParameterResult.pr = [];
      this.p_aiRobotMatchParameterResult.op = '';
      this.p_aiRobotMatchParameterResult.op_acc = 0;
      this.p_aiRobotMatchParameterResult.acc = 0;
      this.p_aiRobotMatchParameterResult.op_data = '';
    },
    f_analyzeTextForWdm: function (audio_file = false) {
      console.log('audio_file: ', audio_file);
      this.d_sttResult = { 'confidence': '', 'text': '' };
      if (this.d_textAnalyze || audio_file) {
        for (let i in this.p_wdmAiList) {
          this.p_wdmAiList[i].analyze_text = this.d_textAnalyze;
          if (this.p_aiRobotMatchParameterResult.op !== '') {
            // console.log("this.p_aiRobotMatchParameterResult.op !== ''");
            this.p_wdmAiList[i].result = { 'pr': [], 'op': '', 'op_acc': 0, 'acc': 0, 'op_data': '' };
            this.f_clearAiRobotMatchParameterResult();
          } else if (this.p_aiRobotMatchParameterResult.pr.length > 0) {
            // console.log("this.p_aiRobotMatchParameterResult.pr.length > 0");
            this.p_wdmAiList[i].result = JSON.parse(JSON.stringify(this.p_aiRobotMatchParameterResult));
          } else {
            // console.log("else");
            this.f_clearAiRobotMatchParameterResult();
            this.p_wdmAiList[i].result = { 'pr': [], 'op': '', 'op_acc': 0, 'acc': 0, 'op_data': '' };
          }
        }
        let data = {
          'type': this.d_analyzeType,
          'analyze_list': this.p_wdmAiList,
          'language': this.d_selectedLang
        };
        if (audio_file) {
          data.speech_to_text = 'yes';
          data.audio_file = audio_file;
        }
        // console.log(data);
        if (audio_file) {
          this.d_waitingForSpeechResponse = true;
        }
        WdmService.analyze_text_for_wdm(data)
          .then(resp => {
            this.d_waitingForSpeechResponse = false;
            if (resp.data.status === 'success') {
              if (resp.data.result.stt) {
                this.d_sttResult = resp.data.result.stt;
              }
              // console.log(resp.data.result);
              for (let i in resp.data.result.analyze_list) {
                for (let r in resp.data.result.analyze_list[i].result) {
                  this.p_wdmAiList[i].result[r] = resp.data.result.analyze_list[i].result[r];
                  this.p_aiRobotMatchParameterResult[r] = resp.data.result.analyze_list[i].result[r];
                }
                // If we have a result for wdmr_data it will come back again to frontend. And we will use it.
                // console.log(resp.data.result.analyze_list[i].wdmr_data);
                if (resp.data.result.analyze_list[i].wdmr_data && Object.keys(resp.data.result.analyze_list[i].wdmr_data).length > 0) {
                  this.p_wdmAiList[i].wdmr_data = resp.data.result.analyze_list[i].wdmr_data;
                }
              }
              this.f_paramChangeFunc();
              if (this.d_analyzeType === 'match_param_option') {
                this.d_textAnalyze = '';
                this.$refs.text_analyze.focus();
              }
              // document.getElementById("myAnchor").focus();
              // console.log(resp.data);
              // We get analyze for every item in the wdm list. Function designed for multi wdm text to parameter analyzes.
              // this.d_errorInText = resp.data.result.list[0].error_in_text;
              // this.d_correctTextList = resp.data.result.list[0].correct_text_list;
            } else {
              alert('Errror: ', resp.data.message);
            }
          });
      } else {
        alert(this.$t('wdm16.12703'));
      }
    },
    f_paramChangeFunc: function () {
      if (this.param_change.wdmr_data_update) {
        this.param_change.wdmr_data_update = 0;
      } else {
        this.param_change.wdmr_data_update = 1;
      }
    }
  },
  watch: {},
  components: {}
};

</script>

<style type="text/css">


</style>

